<template>
  <div class="course-box">
    <el-card shadow="never" class="margin-lt">
      <div class="flex flex-v-center" slot="header">
        <!-- <h3 slot="header">PTE资讯</h3> -->

        <el-menu :default-active="active" class="margin-x" mode="horizontal" @select="handleSelect">
          <el-menu-item class="bold m-hover" v-for="(type, index) in articleTypes" :key="index"
            :index="type.id.toString()">{{
              $t1(type, 'title')
            }}</el-menu-item>
        </el-menu>
      </div>

      <div style="background-color:#f0f0f0;">
        <el-card shadow="never" class="padding el-menu-item" style="margin-bottom:1px;height:auto;"
          v-for="(item, index) in $store.getters.getArticles($store.state.articleType)" :key="index">
          <div @click="to(item)" class="flex pointer">
            <el-image @click="to(item)" slot="header" :src="$host + $url(item, 'cover')" fit="cover"
              style="width: 100px;height: 100px"></el-image>
            <div class="margin-x flex1">
              <h3>{{ $t1(item, 'title') }}</h3>
              <div class="mt1 margin-t">{{ $t1(item, 'description') }}</div>
            </div>
          </div>
        </el-card>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      index: 0,
    }
  },



  created() {
    this.$store.dispatch("fetch_articles");
    this.$store.dispatch("fetch_article_types");

    if (this.$route.hash) {
      this.index = parseInt(this.$route.hash.replace("#", ""));
    } else {
      if (this.$store.state.mode == 'CCL') {
        let type = this.articleTypes.find((val) => {
          return val.title == "CCL";
        });
        if (type) {
          this.index = type.id;
        }
      } else {
        this.index = this.articleTypes[0].id;
      }
      this.$store.commit("setArticleType", this.index);
    }
  },


  watch: {
    $route(val) {
      if (val.hash) {
        this.index = parseInt(this.$route.hash.replace("#", ""));
      } else {


        if (this.$store.state.mode == 'CCL') {
          let type = this.articleTypes.find((val) => {
            return val.title == "CCL";
          });
          if (type) {
            this.index = type.id;
          }
        } else {
          this.index = this.articleTypes[0].id;
        }
      }
      this.$store.commit("setArticleType", this.index);
    },
    articleTypes(val) {
      if (this.index == 0) {

        if (this.$store.state.mode == "CCL") {
          let type = this.articleTypes.find((val) => {
            return val.title == "CCL";
          });
          if (type) {
            this.index = type.id;
            this.$store.commit("setArticleType", type.id);
          }
        } else {
          this.$store.commit("setArticleType", val[0].id);
        }

      } else {
        this.$store.commit("setArticleType", this.index);
      }
    },
    active: function (val) {
      console.log(val)
    }
  },





  computed: {
    active: function () {
      return this.$store.state.articleType.toString();
    },

    articleTypes: function () {
      const type = this.$store.state.mode == 'CCL' ? 1 : 0;
      return this.$store.state.articleTypes.filter((val) => { return val.type == type });
    },
  },

  methods: {

    to(item) {
      this.$router.push("/media?tag=article&id=" + item.id)
    },

    handleSelect(index) {
      this.$router.replace("/article#" + index);
    },
  },
};
</script>

<style scoped>
.course-box {
  margin: 40px 15%;
  position: relative;
}

.course-box>>>.el-card {
  border: none !important;
}

.course-box>>>.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none !important;
  color: #e6a13c;
}

.course-box>>>.el-menu--horizontal>.el-menu-item {
  border-bottom: none !important;
}

.img-item>>>.el-image {
  height: 200px;
}

.img-item>>>.el-card__header {
  padding: 0 !important;
}
</style>
